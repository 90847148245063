import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { WalletContext } from './common/components/wallet/WalletContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<WalletContext><App /></WalletContext>);

serviceWorkerRegistration.register();
