import React, { FC, ReactNode, SyntheticEvent, useMemo } from 'react';
import { message } from 'antd';
import { CopyIcon } from '../../icons';

export interface CopyTextProps {
  text?: string;
  children?: ReactNode;
  onClick?: (e: unknown) => void;
}

const CopyText: FC<CopyTextProps> = ({ text, children, onClick }) => {

  const onCopy = async (e: SyntheticEvent) => {
    e.stopPropagation();
    await window.navigator.clipboard.writeText(text ?? '');
    message.success('Copied to clipboard!');
  };

  const hasHandler = useMemo<string>(() => {
    return typeof onClick === 'function' ? 'cursor-pointer hover:text-[#616774] duration-200' : '';
  }, [onClick]);

  const handleClick = (e: SyntheticEvent) => {
    if (typeof onClick === 'function') {
      onClick(e);
    }
  };

  return (
    <span onClick={handleClick}
          className={`inline-flex flex-row items-center text-[#292E3E] ${hasHandler}`}>
      {children ? children : text}
      <a onClick={e => onCopy(e)}>
        <CopyIcon className={'text-green-400 cursor-pointer ml-1 duration'} />
      </a>
    </span>
  );
};

export default CopyText;
