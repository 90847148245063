import { useState } from 'react';

// Hook
export function useLocalStorage<T>(key: string, initialValue: T): [T, (value: T) => void, () => void] {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);
      const parse = item ? JSON.parse(item) : {};
      return initialValue ? { ...initialValue, ...parse } : parse;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: T): void => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteValue = (): void => {
    try {
      if (typeof window !== 'undefined') {
        if (window.localStorage.getItem(key)) {
          window.localStorage.removeItem(key);
          setStoredValue(initialValue);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue, deleteValue];
}
