const {
  REACT_APP_URLS: URLS,
  REACT_APP_GOVERNANCE_PROGRAM: GOVERNANCE_PROGRAM,
  REACT_APP_NEON_EVM_PROGRAM: NEON_EVM_PROGRAM,
  REACT_APP_MAINTENANCE_PROGRAM: MAINTENANCE_PROGRAM,
  REACT_APP_MULTISIG_PROGRAM: MULTISIG_PROGRAM,
  REACT_APP_MEMO_PROGRAM: MEMO_PROGRAM,
  REACT_APP_FEE_PAYER: FEE_PAYER
} = process.env;

const urls = JSON.parse(URLS ?? '') ?? {
  solanaRpcApi: 'https://solana.premainnet.neontest.xyz/',
  neonProxyRpcApi: 'http://premainnet.neontest.xyz/solana'
};

export const environment = {
  governanceProgram: GOVERNANCE_PROGRAM ?? '82pQHEmBbW6CQS8GzLP3WE2pCgMUPSW2XzpuSih3aFDk', // GOVERNANCE_PROGRAM
  neonEvmProgram: NEON_EVM_PROGRAM ?? 'DCPSnJHB38e7vNK6o3AVLswJGRaP87iiNx2zvvapiKBz', // NEON_EVM_PROGRAM
  maintenanceProgram: MAINTENANCE_PROGRAM ?? '7aPH9mBAvUtJDGV2L1KyvpR5nKF7man5DZzBPaxmisg5', // MAINTENANCE_PROGRAM
  multisigProgram: MULTISIG_PROGRAM ?? 'C7VZKm4FxXEwrtNGtKzH7K3x1CJfoxSV3vKFqPnEpTqb', // MULTISIG_PROGRAM
  memo: MEMO_PROGRAM ?? 'MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr', // MEMO_PROGRAM
  payer: FEE_PAYER ?? '9G9A27t7FEEP5L53svAPweJyZCDFESsGd5tvnQEZFJUY', // FEE_PAYER
  url: {
    explorer: 'https://explorer.solana.com',
    solanaRpcApi: urls.solanaRpcApi,
    neonProxyRpcApi: urls.neonProxyRpcApi
  }
};
