import React, { FC, useEffect, useMemo, useState } from 'react';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { Button, message, Switch } from 'antd';
import { useNavigate } from 'react-router-dom';
import { sol } from '../../utils';
import { CloseIcon, MenuDotsIcon } from '../../common/icons';
import ExplorerCopy from '../../common/components/common/ExplorerCopy';
import { RoutePath } from '../../models';
import { AccountData, AccountDataStorage, LoginState } from '../models';
import { useProgram } from '../../common/components/wallet/ProgramContext';

const Account: FC<{ account?: AccountData, login: LoginState, className: string }> = (props) => {
  const navigate = useNavigate();
  const { accountStorage } = useProgram();
  const { setAccount, account: dataAccount } = accountStorage;
  const { account, className } = props;
  const { connection } = useConnection();
  const { publicKey, disconnecting, disconnect } = useWallet();
  const [balance, setBalance] = useState(sol(0));
  const [show, setShow] = useState<boolean>(false);

  const walletKey = useMemo(() => {
    return publicKey?.toBase58() ?? ``;
  }, [publicKey]);

  const toggleAccount = () => setShow(!show);

  const balanceRequest = async () => {
    if (publicKey) {
      const balance = await connection.getBalance(publicKey);
      setBalance(sol(balance));
    }
  };

  const onChange = async (advanced: boolean) => {
    if (setAccount && accountStorage) {
      await setAccount({ ...dataAccount, advanced });
    }
  };

  const onDisconnect = async (): Promise<void> => {
    await disconnect().then(() => {
      setAccount && setAccount({} as AccountDataStorage);
      message.success('Disconnected successful!');
    }).catch(() => {
      message.error('Something was wrong...');
    }).finally(() => {
      navigate(`/${RoutePath.proposals}`, { replace: true });
    });
  };

  useEffect(() => {
    balanceRequest();
  }, [publicKey]);

  return <>
    {show && <div onClick={toggleAccount}
                  className={`fixed bg-[#00000026] top-0 right-0 bottom-0 left-0 backdrop-blur-[5.5px] ease-in-out duration-200 opacity-1 z-10`} />}
    <a onClick={toggleAccount} className={`p-4 inline-block rounded-[10px] bg-[#F5F5F5] ${className}`}>
      <MenuDotsIcon />
    </a>
    {show &&
      <div className={'flex flex-row justify-center fixed left-0 top-0 w-full z-20'} onClick={toggleAccount}>
        <div className={'max-w-2xl w-full'}>
          <div className={'p-4 m-4 bg-[#F3F3F3] rounded-[10px]'} onClick={e => e.stopPropagation()}>
            <div className={'flex flex-row justify-between text-base mb-4'}>
              <dl><h3 className={'text-xl'}>Engineer profile</h3></dl>
              <dd>
                <a onClick={toggleAccount} className={'btn-sm icon border-none'}><CloseIcon /></a>
              </dd>
            </div>
            <div className={'flex flex-row mb-4 justify-between text-base whitespace-nowrap'}>
              {account && <span className={'mr-2'}>{account.name}</span>}
              <ExplorerCopy hash={walletKey} type={'address'} className={'bold w-full justify-end'} />
            </div>
            <dl className={'flex flex-row justify-between text-base mb-4'}>
              <dt>Balance</dt>
              <dd onClick={balanceRequest}>{balance}</dd>
            </dl>
            <dl className={'flex flex-row justify-between text-base mb-7'}>
              <dt>Advanced mode</dt>
              <dd><Switch onChange={onChange} checked={dataAccount?.advanced} /></dd>
            </dl>
            <div className={'text-center'}>
              <Button className={'button transparent rounded-full w-full max-w-md'} onClick={onDisconnect}
                      loading={disconnecting}>
                Disconnect wallet
              </Button>
            </div>
          </div>
        </div>
      </div>}
  </>;
};

export default Account;
