import { useConnection } from '@solana/wallet-adapter-react';
import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { MaintenanceProgram } from '../../../programs/maintenance/program';
import { environment } from '../../../environments/config/environment';
import { CommonApi } from '../../../api/CommonApi';
import { AccountData, ProgramContextState } from '../../../maintenance/models';
import {
  useAccountStorage,
  useBufferStorage,
  useFormStorage,
  useRequestBuffers,
  useSettingsStorage
} from '../../../hooks';
import useProgramBuffer from '../../../hooks/program-buffer';
import { ConfirmDialogProvider } from '../common/ConfirmDialog';

export const ProgramContext = createContext<ProgramContextState>({} as ProgramContextState);

export function useProgram(): ProgramContextState {
  return useContext(ProgramContext);
}

export const ProgramProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [accounts, setAccounts] = useState<AccountData[]>([]);
  const { connection } = useConnection();
  const [settings] = useSettingsStorage();
  const api = new CommonApi(settings);
  const accountStorage = useAccountStorage();
  const bufferStorage = useBufferStorage();
  const formStorage = useFormStorage();

  const program = useMemo(() => {
    return new MaintenanceProgram({
      maintenance: settings.maintenanceProgramId!,
      neonEvm: settings.neonEvmProgramId!,
      multisig: settings.multisigProgramId!,
      memo: environment.memo,
      payer: environment.payer,
      connection
    });
  }, [connection]);

  const buffers = useRequestBuffers(program);
  const programBuffer = useProgramBuffer(program, 15000);

  useEffect(() => {
    (async () => {
      await program.initMaintenanceData();
      api.engineers().then(setAccounts);
    })();
  }, [program]);

  return (
    <ProgramContext.Provider
      value={{
        program,
        api,
        accounts,
        accountStorage,
        buffers,
        bufferStorage,
        formStorage,
        programBuffer
      }}>
      <ConfirmDialogProvider>
        {children}
      </ConfirmDialogProvider>
    </ProgramContext.Provider>
  );
};
