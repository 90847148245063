import * as bs58 from 'bs58';
import {
  AccountInfo,
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction
} from '@solana/web3.js';
import { WalletContextState } from '@solana/wallet-adapter-react/lib/types/useWallet';
import { deserialize } from 'borsh';
import { Buffer } from 'buffer';
import { arrayEquals } from '../../utils';
import { GroupFormData, GroupsState } from '../../models';
import {
  GroupData,
  GroupMember,
  InitInstruction,
  multiSigSchema,
  ProtectedAccountConfig
} from './models/schema';
import { ACCOUNT_TYPE_TAG, MultiSigProgram } from './program';
import { MaintenanceRecord } from '../maintenance/schema';

export function deserializeGroupData(buffer: AccountInfo<Buffer>): GroupData {
  return deserialize<GroupData>(multiSigSchema, GroupData, buffer.data.slice(1));
}

export async function groupsList(connection: Connection, program: MultiSigProgram, publicKey: PublicKey): Promise<GroupsState[]> {
  try {
    const bytes = bs58.encode([ACCOUNT_TYPE_TAG['group']]);
    const memFilter = { memcmp: { bytes, offset: 0 } };
    const accounts = await connection.getProgramAccounts(program.programId, {
      commitment: 'confirmed',
      filters: [memFilter]
    });
    const signerKeyBytes = publicKey.toBytes();
    return accounts.map((data) => {
      return { group: deserializeGroupData(data.account), publicKey: data.pubkey };
    }).filter(({ group }) => {
      return group.members.some(member => arrayEquals(member.publicKey, signerKeyBytes));
    });
  } catch (e) {
    console.log(e);
  }
  return [];
}

export async function maintenanceGroupsList(connection: Connection, multiSig: MultiSigProgram, maintenanceRecordData?: MaintenanceRecord): Promise<any> {
  const groups: GroupsState[] = [];
  try {
    const bytes = bs58.encode([ACCOUNT_TYPE_TAG['group']]);
    const memFilter = { memcmp: { bytes, offset: 0 } };
    const accounts = await connection.getProgramAccounts(multiSig.programId, {
      commitment: 'confirmed',
      filters: [memFilter]
    });

    for (const data of accounts) {
      const protectedKey = await multiSig.protectedAccountKey(data.pubkey);
      if (maintenanceRecordData?.delegate.some(d => d.equals(protectedKey))) {
        groups.push({ group: deserializeGroupData(data.account), publicKey: data.pubkey });
      }
    }
  } catch (e) {
    console.log(e);
  }
  return groups;
}

export async function groupCreate(context: { connection: Connection, program: MultiSigProgram, publicKey: PublicKey, sendTransaction: WalletContextState['sendTransaction'], data: GroupFormData }): Promise<any> {
  const { data, connection, program, publicKey, sendTransaction } = context;

  const threshold = data.threshold;
  const members = data.members.map((member) => {
    const publicKey = new PublicKey(member.pubkey).toBytes();
    const weight = member.weight;
    return new GroupMember({ publicKey, weight });
  });

  const groupData = new GroupData({ threshold, members });

  const signerAccount = publicKey.toBase58();
  console.log(`Signing with account: ${signerAccount}`);

  const groupKey = await program.groupAccountKey(groupData);
  console.log(`Group account: ${groupKey.toBase58()}`);

  const protectedAccount = await program.protectedAccountKey(groupKey);
  console.log(`Protected account: ${protectedAccount.toBase58()}`);

  const groupAccountInfo = await connection.getAccountInfo(groupKey);
  if (groupAccountInfo) {
    console.log(`Group Account already exists`);
    const groupData = program.readGroupAccountData(groupAccountInfo);
    console.log(`Group threshold: ${groupData.threshold}`);
    console.table(groupData.members.map(member => ({
      member: new PublicKey(member.publicKey).toBase58(),
      weight: member.weight
    })));
    return groupKey;
  }

  const lamports = await connection.getMinimumBalanceForRentExemption(program.groupAccountSpace(groupData));

  console.log(`${lamports / LAMPORTS_PER_SOL} SOL will be transferred to the new group account`);

  let protectedAccountConfig = null;
  if (data.createProtected) {
    const protectedSpace = data.protectedStace ? data.protectedStace : 0;
    const protectedLamports = data.protectedLamports ? data.protectedLamports :
      await connection.getMinimumBalanceForRentExemption(protectedSpace);

    const protectedOwner = data.protectedOwner ? new PublicKey(data.protectedOwner) : SystemProgram.programId;

    console.log(`Protected Account will be created with ${protectedSpace} space and ${protectedOwner.toBase58()} owner`);
    console.log(`${protectedLamports / LAMPORTS_PER_SOL} SOL will be transferred to the protected account`);

    protectedAccountConfig = new ProtectedAccountConfig(protectedLamports, protectedSpace, protectedOwner);
  }

  const initInstruction = new InitInstruction(groupData, lamports, protectedAccountConfig);

  const transaction = new Transaction().add(await program.group(initInstruction, publicKey));

  await sendTransaction(transaction, connection);

  return groupKey;
}
