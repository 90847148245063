import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Loading from '../../common/components/common/Loading';

const LayoutContent: FC = () => {
  return (<div className='flex flex-col min-h-screen'>
    <Header />
    <main className={'flex-grow pt-20'}>
      <div className={'max-w-6xl mx-auto px-5 py-4 sm:px-6'}>
        <React.Suspense fallback={<Loading />}>
          <Outlet />
        </React.Suspense>
      </div>
    </main>
    <Footer />
  </div>);
};

export default LayoutContent;
