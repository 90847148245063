import { useWallet } from '@solana/wallet-adapter-react';
import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import Loading from '../../common/components/common/Loading';
import WalletConnect from '../components/WalletConnect';

const NewLayout = () => {
  const { connecting, connected, publicKey: walletKey } = useWallet();

  const isUnconnected = useMemo(() => {
    return connecting ? false : !walletKey;
  }, [connecting, connected, walletKey]);

  return (<div className={'flex flex-col min-h-screen'}>
    <main className={'flex flex-grow min-h-full'}>
      <div className={'flex flex-col items-center p-4 w-full max-w-2xl mx-auto'}>
        <React.Suspense fallback={<Loading />}>
          <Outlet />
        </React.Suspense>
      </div>
    </main>
    {isUnconnected && <WalletConnect />}
  </div>);
};

export default NewLayout;
