import React, { useMemo, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { MdSettings } from 'react-icons/md';
import { encode } from 'bs58';
import { ArrowDownIcon } from '../../common/icons';
import ExplorerCopy from '../../common/components/common/ExplorerCopy';
import { useProgram } from '../../common/components/wallet/ProgramContext';
import { useOnClickOutside } from '../../hooks';
import { RoutePath } from '../../models';
import { NeonProgram, ProgramStatus } from '../models';
import { useProgramStatus } from '../../hooks/program-status';

const EvmStatus = () => {
  const bodyRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState<boolean>(false);
  const [neonProgram, setProgram] = useState<NeonProgram>();
  const { program, programBuffer } = useProgram();
  const { status, version, statusBg } = useProgramStatus();

  const evmStatus = useMemo(() => {
    let st = 'None';
    switch (status) {
      case ProgramStatus.work:
        st = 'ON';
        break;
      case ProgramStatus.stop:
        st = 'OFF';
        break;
    }
    return `EVM ${version} is ${st}`;
  }, [version, status]);

  const programHash = useMemo(() => {
    const [buffer] = programBuffer ?? [];
    if (buffer) {
      return encode(buffer.hash);
    }
    return ``;
  }, [programBuffer]);

  const showStyles = useMemo<object>(() => {
    return {
      height: show ? 'auto' : '0',
      opacity: show ? 1 : 0,
      paddingTop: show ? '14px' : 0,
      visibility: show ? 'visible' : 'hidden'
    };
  }, [show]);

  const toggleShow = (): void => {
    setShow(!show);
    accountInfo();
  };

  const accountInfo = async (): Promise<void> => {
    const [authorityKey] = await program.getMaintenanceRecordAddress(program.neonEvmKey, program.maintenanceKey);
    await setProgram({
      programKey: program.neonEvmKey.toBase58(),
      authorityKey: authorityKey.toBase58()
    });
  };

  useOnClickOutside(bodyRef, () => {
    setShow(false);
  });

  return <>
    {show && <div
      className={'fixed bg-[#00000026] top-0 right-0 bottom-0 left-0 backdrop-blur-[5.5px] ease-in-out duration-200 opacity-1 z-10'} />}
    <div ref={bodyRef} className={`relative w-full z-10 h-14`}>
      <div className={'absolute w-full p-4 rounded-[10px] bg-[#F5F5F5] cursor-pointer'}
           onClick={toggleShow}>
        <div className={'flex flex-row items-center'}>
          <div className={'flex flex-row items-center w-full'}>
            <NavLink to={`/${RoutePath.home}`} onClick={e => e.stopPropagation()}
                     className={'w-[16px] h-[16px] rounded-full transition-colors duration-150'}
                     style={{ background: statusBg.color }} />
            <a className={'ml-2 text-base text-[#06010D]'}>{evmStatus}</a>
          </div>
          <ArrowDownIcon className={`ease-in-out duration-200 ${show ? 'rotate-180' : ''}`} />
        </div>
        <div
          className={`flex flex-row justify-center w-full z-20 overflow-hidden ease-in-out duration-200`}
          style={showStyles}>
          <div className={'w-full'}>
            {neonProgram?.programKey &&
              <dl className={'flex flex-col text-[#7C7C7C] text-base mb-3'}>
                <dt className={'text-base bold text-black'}>Program</dt>
                <dd className={'flex flex-row'}>
                  <ExplorerCopy hash={neonProgram?.programKey} type='address' />
                </dd>
              </dl>}
            {neonProgram?.authorityKey &&
              <dl className={'flex flex-col text-[#7C7C7C] text-base'}>
                <dt className={'text-base bold text-black'}>Program hash</dt>
                <dd className={'flex flex-row'}>
                  <ExplorerCopy hash={programHash} type='hash' />
                </dd>
              </dl>}
            <Link to={`/${RoutePath.network}`}
                  className={'btn-sm icon absolute right-4 bottom-4 z-10'}>
              <MdSettings />
            </Link>
          </div>
        </div>
      </div>
    </div>
  </>;
};

export default EvmStatus;
