import { ProgramStatus } from '../maintenance/models';

export interface RPCResponse<T> {
  id: number | string;
  jsonrpc: string;
  result: T;
}

export const enum NeonKey {
  ADDITIONAL_FEE = 'NEON_ADDITIONAL_FEE',
  CHAIN_ID = 'NEON_CHAIN_ID',
  COMPUTE_UNITS = 'NEON_COMPUTE_UNITS',
  GAS_LIMIT_MULTIPLIER_NO_CHAINID = 'NEON_GAS_LIMIT_MULTIPLIER_NO_CHAINID',
  HEAP_FRAME = 'NEON_HEAP_FRAME',
  HOLDER_MSG_SIZE = 'NEON_HOLDER_MSG_SIZE',
  MINIMAL_CLIENT_ALLOWANCE_BALANCE = 'NEON_MINIMAL_CLIENT_ALLOWANCE_BALANCE',
  MINIMAL_CONTRACT_ALLOWANCE_BALANCE = 'NEON_MINIMAL_CONTRACT_ALLOWANCE_BALANCE',
  PAYMENT_TO_DEPOSIT = 'NEON_PAYMENT_TO_DEPOSIT',
  PAYMENT_TO_TREASURE = 'NEON_PAYMENT_TO_TREASURE',
  PERMISSION_ALLOWANCE_TOKEN = 'NEON_PERMISSION_ALLOWANCE_TOKEN',
  PERMISSION_DENIAL_TOKEN = 'NEON_PERMISSION_DENIAL_TOKEN',
  PKG_VERSION = 'NEON_PKG_VERSION',
  POOL_BASE = 'NEON_POOL_BASE',
  POOL_COUNT = 'NEON_POOL_COUNT',
  REVISION = 'NEON_REVISION',
  SEED_VERSION = 'NEON_SEED_VERSION',
  STATUS_NAME = 'NEON_STATUS_NAME',
  STORAGE_ENTIRIES_IN_CONTRACT_ACCOUNT = 'NEON_STORAGE_ENTIRIES_IN_CONTRACT_ACCOUNT',
  TOKEN_MINT = 'NEON_TOKEN_MINT',
  TOKEN_MINT_DECIMALS = 'NEON_TOKEN_MINT_DECIMALS',
}

export interface NeonProgramStatus {
  [NeonKey.ADDITIONAL_FEE]: string;
  [NeonKey.CHAIN_ID]: string;
  [NeonKey.COMPUTE_UNITS]: string;
  [NeonKey.GAS_LIMIT_MULTIPLIER_NO_CHAINID]: string;
  [NeonKey.HEAP_FRAME]: string;
  [NeonKey.HOLDER_MSG_SIZE]: string;
  [NeonKey.MINIMAL_CLIENT_ALLOWANCE_BALANCE]: string;
  [NeonKey.MINIMAL_CONTRACT_ALLOWANCE_BALANCE]: string;
  [NeonKey.PAYMENT_TO_DEPOSIT]: string;
  [NeonKey.PAYMENT_TO_TREASURE]: string;
  [NeonKey.PERMISSION_ALLOWANCE_TOKEN]: string;
  [NeonKey.PERMISSION_DENIAL_TOKEN]: string;
  [NeonKey.PKG_VERSION]: string;
  [NeonKey.POOL_BASE]: string;
  [NeonKey.POOL_COUNT]: string;
  [NeonKey.REVISION]: string;
  [NeonKey.SEED_VERSION]: string;
  [NeonKey.STATUS_NAME]: ProgramStatus | string;
  [NeonKey.STORAGE_ENTIRIES_IN_CONTRACT_ACCOUNT]: string;
  [NeonKey.TOKEN_MINT]: string;
  [NeonKey.TOKEN_MINT_DECIMALS]: string;
}
