import { notification } from 'antd';
import React from 'react';
import { MdLink } from 'react-icons/md';
import { stringShort } from '../../../utils';
import { environment } from '../../../environments/config/environment';

export const signatureLink = (explorerUrl: string, signature: string, params?: Record<string, string>): string => {
  return `${explorerUrl}/tx/${signature}${params ? `?${new URLSearchParams(params).toString()}` : ''}`;
};

export function successNotification(context: { signature: string, duration?: number, params?: Record<string, string>, message: string, explorerUrl?: string }): void {
  const [api] = notification.useNotification();
  const { signature, duration = 30, params, message, explorerUrl } = context;
  const url = explorerUrl ?? environment.url.explorer;
  const href = signatureLink(url, signature, params);
  console.log(`Transaction signature: ${signature}`);

  return api.success({
    message: 'Success!', duration, placement: 'bottomRight', description: (<div>
      <div>{message}</div>
      <div><strong>Signature:</strong> <a href={href} target='_blank' rel='noreferrer'>
        {stringShort(signature.toString())} <MdLink
        className={'inline-block text-green-300'} /></a>
      </div>
    </div>)
  });
}
