import {
  AccountInfo,
  AccountMeta,
  PublicKey,
  SystemProgram,
  TransactionInstruction
} from '@solana/web3.js';
import { serialize } from 'borsh';
import CryptoJS from 'crypto-js';
import { Buffer } from 'buffer';
import {
  ApproveInstruction,
  CloseProposalInstruction,
  GroupData,
  InitInstruction,
  InstructionData,
  multiSigSchema,
  ProposalConfig,
  ProposalData,
  ProposalState,
  ProposedAccountMeta,
  ProposedInstruction,
  ProposeInstruction
} from './models/schema';
import { deserializeGroupProposalData } from './proposals';
import { deserializeGroupData } from './groups';

export class MultiSigProgram {
  programId: PublicKey;

  constructor(programId: PublicKey) {
    this.programId = programId;
  }

  async groupAccountKey(groupData: GroupData): Promise<PublicKey> {
    const serializedGroup = serialize(multiSigSchema, groupData);
    const hash_str = CryptoJS.SHA256(byteArrayToWordArray(serializedGroup)).toString();
    const hash = Buffer.from(hash_str, 'hex');
    const [groupKey] = await PublicKey.findProgramAddress([PDA_TAG.group, hash], this.programId);
    return groupKey;
  }

  async protectedAccountKey(groupAccountKey: PublicKey): Promise<PublicKey> {
    const [protectedAccount] = await PublicKey.findProgramAddress([PDA_TAG.protected, groupAccountKey.toBuffer()], this.programId);
    return protectedAccount;
  }

  async proposalAccountKey(proposalConfig: ProposalConfig): Promise<PublicKey> {
    const serializedProposedConfig = serialize(multiSigSchema, proposalConfig);
    const hash_str = CryptoJS.SHA256(byteArrayToWordArray(serializedProposedConfig)).toString();
    const hash = Buffer.from(hash_str, 'hex');
    const [proposalKey] = await PublicKey.findProgramAddress([PDA_TAG.proposal, hash], this.programId);
    return proposalKey;
  }

  groupAccountSpace(groupData: GroupData): number {
    const serializedGroup = serialize(multiSigSchema, groupData);
    return serializedGroup.length + 1;
  }

  proposalAccountSpace(config: ProposalConfig): number {
    const mockState = new ProposalState({ members: 1, current_weight: 1 });
    return (serialize(multiSigSchema, new ProposalData({ state: mockState, config })).length +
      4 /* length */ +
      1 /* tag */
    );
  }

  readGroupAccountData(info: AccountInfo<Buffer>): GroupData {
    if (!info.owner.equals(this.programId)) {
      throw 'Error: Invalid account owner';
    }

    checkAccountType(info.data, ACCOUNT_TYPE_TAG.group);
    return deserializeGroupData(info);
  }

  readProposalAccountData(info: AccountInfo<Buffer>): ProposalData {
    if (!info.owner.equals(this.programId)) {
      throw 'Error: Invalid account owner';
    }

    if (!anyNonZero(info.data)) {
      throw 'Data is zero (Proposal may be complete)';
    }

    checkAccountType(info.data, ACCOUNT_TYPE_TAG.proposal);
    return deserializeGroupProposalData(info);
  }

  async group(data: InitInstruction, payerAccountKey: PublicKey): Promise<TransactionInstruction> {
    const instructionData = new InstructionData(data);
    const instructionBuffer = serialize(multiSigSchema, instructionData);
    const groupAccountKey = await this.groupAccountKey(data.group_data);
    const protectedAccountKey = await this.protectedAccountKey(groupAccountKey);

    return new TransactionInstruction({
      keys: [
        { pubkey: payerAccountKey, isSigner: true, isWritable: true },
        { pubkey: groupAccountKey, isSigner: false, isWritable: true },
        { pubkey: SystemProgram.programId, isSigner: false, isWritable: false },
        { pubkey: protectedAccountKey, isSigner: false, isWritable: true }
      ],
      programId: this.programId,
      data: Buffer.from(instructionBuffer)
    });
  }

  async propose(data: ProposeInstruction, groupAccountKey: PublicKey, signerAccountKey: PublicKey): Promise<TransactionInstruction> {
    const proposalConfig = new ProposalConfig({
      group: Uint8Array.from(groupAccountKey.toBuffer()),
      instructions: data.instructions,
      author: Uint8Array.from(signerAccountKey.toBuffer()),
      salt: data.salt
    });
    const proposalKey = await this.proposalAccountKey(proposalConfig);
    const protectedAccountKey = await this.protectedAccountKey(groupAccountKey);
    console.log(`Protected account : ${protectedAccountKey.toBase58()}`);

    const instructionData = new InstructionData(data);
    const buffer = serialize(multiSigSchema, instructionData);

    const programIdAccounts = data.instructions.map((instruction: ProposedInstruction) => ({
      pubkey: new PublicKey(instruction.program_id),
      isSigner: false,
      isWritable: false
    }));
    const instructionAccounts: AccountMeta[] = data.instructions.flatMap((instruction: ProposedInstruction) =>
      instruction.accounts.map((account: ProposedAccountMeta) => ({
        pubkey: new PublicKey(account.pubkey),
        isSigner: false,
        isWritable: account.is_writable
      })));

    return new TransactionInstruction({
      keys: [
        { pubkey: signerAccountKey, isSigner: true, isWritable: true },
        { pubkey: groupAccountKey, isSigner: false, isWritable: true },
        { pubkey: proposalKey, isSigner: false, isWritable: true },
        { pubkey: SystemProgram.programId, isSigner: false, isWritable: false },
        ...programIdAccounts,
        ...instructionAccounts
      ],
      programId: this.programId,
      data: Buffer.from(buffer)
    });
  }

  async approve(proposalAccountKey: PublicKey, proposalConfig: ProposalConfig, signerAccountKey: PublicKey): Promise<TransactionInstruction> {
    const groupAccountKey = new PublicKey(proposalConfig.group);
    const protectedAccountKey = await this.protectedAccountKey(groupAccountKey);

    const instructionData = new InstructionData(new ApproveInstruction());
    const buffer = serialize(multiSigSchema, instructionData);

    const programIdAccounts = proposalConfig.instructions.map(
      (instruction: ProposedInstruction) => ({
        pubkey: new PublicKey(instruction.program_id),
        isSigner: false,
        isWritable: false
      })
    );
    const instructionAccounts: AccountMeta[] = proposalConfig.instructions.flatMap(
      (instruction: ProposedInstruction) =>
        instruction.accounts
          .filter((account: ProposedAccountMeta) => !protectedAccountKey.equals(new PublicKey(account.pubkey)))
          .map((account: ProposedAccountMeta) => ({
            pubkey: new PublicKey(account.pubkey),
            isSigner: false,
            isWritable: account.is_writable
          }))
    );

    return new TransactionInstruction({
      keys: [
        { pubkey: signerAccountKey, isSigner: true, isWritable: true },
        { pubkey: groupAccountKey, isSigner: false, isWritable: true },
        { pubkey: proposalAccountKey, isSigner: false, isWritable: true },
        { pubkey: protectedAccountKey, isSigner: false, isWritable: true },
        ...programIdAccounts,
        ...instructionAccounts
      ],
      programId: this.programId,
      data: Buffer.from(buffer)
    });
  }

  closeProposal(
    proposalAccountKey: PublicKey,
    signerAccountKey: PublicKey,
    destinationKey: PublicKey
  ): TransactionInstruction {
    const instructionData = new InstructionData(new CloseProposalInstruction());
    const buffer = serialize(multiSigSchema, instructionData);
    const keys = [
      { pubkey: signerAccountKey, isSigner: true, isWritable: true },
      { pubkey: proposalAccountKey, isSigner: false, isWritable: true },
      { pubkey: destinationKey, isSigner: false, isWritable: true }
    ];
    return new TransactionInstruction({
      programId: this.programId,
      keys,
      data: Buffer.from(buffer)
    });
  }
}

export const PDA_TAG = {
  group: Buffer.from([0]),
  proposal: Buffer.from([1]),
  protected: Buffer.from([2])
};

export const ACCOUNT_TYPE_TAG = {
  group: 1,
  proposal: 2
};

function checkAccountType(data: Buffer, expected: number) {
  if (data.length == 0) {
    throw 'Account data is empty';
  }
  if (data[0] != expected) {
    throw 'Invalid account type';
  }
}

function anyNonZero(buffer: Buffer): boolean {
  for (const [_, byte] of buffer.entries()) {
    if (byte != 0) {
      return true;
    }
  }
  return false;
}

function byteArrayToWordArray(ba: Uint8Array): CryptoJS.lib.WordArray {
  const wa: number[] = [];
  for (let i = 0; i < ba.length; i++) {
    wa[(i / 4) | 0] |= ba[i] << (24 - 8 * i);
  }

  return CryptoJS.lib.WordArray.create(wa, ba.length);
}
