import { Rule } from 'rc-field-form/lib/interface';
import { PublicKey } from '@solana/web3.js';

export function required(message = 'Field is required'): Rule {
  return { required: true, message };
}

export const validPublicKey = (): Rule => ({
  validator(_, value) {
    try {
      if (value?.length) {
        new PublicKey(value);
      }
      return Promise.resolve();
    } catch {
      return Promise.reject('Invalid Public key');
    }
  },
});
