import { PublicKey } from '@solana/web3.js';
import { Schema } from 'borsh';
import { Buffer } from 'buffer';
import { createHash } from 'sha256-uint8array';
import { open } from 'elfinfo';
import BN from 'bn.js';
import { ELFOpenResult, ELFSymbolSection } from 'elfinfo/src/types';
import { findBufferLength } from '../../utils';
import { ProgramDataStorage } from '../../maintenance/models';
import { NeonKey, ProgramBufferType } from '../../models';
import { UpgradeableLoaderState } from '../multisig/models/proposal';

export class NeonProgramData {
  params: Map<NeonKey, string> = new Map();
  data: Uint8Array;

  constructor(result: ELFOpenResult, data: Uint8Array) {
    this.data = data;
    const { elf } = result;
    if (elf) {
      const _dyn_sym = '46,100,121,110,115,121,109';
      const paramsIndex = elf.sections.findIndex(i => i.name === _dyn_sym);
      if (paramsIndex > -1) {
        const section: ELFSymbolSection = elf.sections[paramsIndex] as ELFSymbolSection;
        for (const symbol of section.symbols) {
          const key: NeonKey = Buffer.from(symbol.name.split(',').map(d => parseInt(d))).toString('utf-8') as NeonKey;
          if (key.includes('NEON')) {
            const address = Number(symbol.virtualAddress);
            this.params.set(key, Buffer.from(data.slice(address, address + symbol.size)).toString('utf8'));
          }
        }
      }
    }
  }
}

export class ProgramBufferData {
  pubkey: PublicKey;
  owner: PublicKey;
  type: UpgradeableLoaderState;
  option: number;
  slot = 0;
  authority: PublicKey;
  data: Uint8Array;
  program: Uint8Array;
  hash: Uint8Array;
  elf?: NeonProgramData;

  constructor(pubkey: PublicKey, owner: PublicKey, data: Uint8Array) {
    this.pubkey = pubkey;
    this.owner = owner;
    this.data = data;
    this.type = new BN(data.slice(0, 4), 'hex', 'le').toNumber();
    switch (this.type) {
      case UpgradeableLoaderState.Buffer:
        this.option = new BN(data.slice(4, 5)).toNumber();
        this.authority = new PublicKey(data.slice(5, 37));
        this.program = data.slice(37);
        break;
      case UpgradeableLoaderState.ProgramData:
        this.slot = new BN(data.slice(4, 12), 'le').toNumber();
        this.option = new BN(data.slice(12, 13)).toNumber();
        this.authority = new PublicKey(data.slice(13, 44));
        this.program = data.slice(45);
        break;
    }
  }

  static async fromStorage(storage: ProgramDataStorage): Promise<ProgramBufferData> {
    const owner = new PublicKey(storage.owner);
    const pubkey = new PublicKey(storage.pubkey);
    const data = Buffer.from(storage.data, 'base64');
    const emptyPart = Buffer.alloc(storage.emptyLength, 0);
    const concat = Buffer.concat([data, emptyPart]);
    const buffer = new ProgramBufferData(pubkey, owner, concat);
    await buffer.getProgramHash();
    await buffer.getNeonProgramData();
    return buffer;
  }

  async toStorage(): Promise<ProgramDataStorage> {
    const bufferLength = findBufferLength(this.data);
    const data = this.data.slice(0, bufferLength);
    const emptyLength = this.data.length - bufferLength;
    return {
      slot: this.slot,
      data: Buffer.from(data).toString('base64'),
      owner: this.owner.toBase58(),
      pubkey: this.pubkey.toBase58(),
      emptyLength
    };
  }

  async getNeonProgramData(data: Uint8Array = this.program): Promise<NeonProgramData> {
    const buffer = await open(data);
    this.elf = new NeonProgramData(buffer, data);
    return this.elf;
  }

  async getProgramHash(): Promise<Uint8Array> {
    this.hash = createHash().update(this.program).digest();
    return this.hash;
  }
}

export class MaintenanceRecord {
  account_type: number;
  maintained_address: PublicKey;
  authority: PublicKey;
  delegate: PublicKey[];
  hashes: Uint8Array[];

  constructor(data: Record<string, any>) {
    this.account_type = data.account_type;
    this.maintained_address = new PublicKey(data.maintained_address);
    this.authority = new PublicKey(data.authority);
    this.delegate = data.delegate.map((d: { d: Uint8Array }) => new PublicKey(d.d));
    this.hashes = data.hashes.map((d: { d: Uint8Array }) => d.d);
  }
}

export class BufferData {
  type: ProgramBufferType;
  timestamp: number;
  comment: string;

  constructor(data: Record<string, any>) {
    this.type = data.type;
    this.timestamp = data.timestamp;
    this.comment = data.comment;
  }
}

export class BufferLoader {
  authority: PublicKey;
  data: Uint8Array;

  constructor(data: Record<string, any>) {
    this.authority = data.authority;
    this.data = data.data;
  }
}

export class DelegateList {
  d: Uint8Array;

  constructor(d: Record<string, any>) {
    this.d = d.d;
  }
}

export class HashesList {
  d: Uint8Array;

  constructor(d: Record<string, any>) {
    this.d = d.d;
  }
}

export const schema: Schema = new Map([
  <any>
    [
      BufferLoader,
      {
        kind: 'struct',
        fields: [
          ['authority', [32]],
          ['data', [32]]
        ]
      }
    ], [
    DelegateList,
    {
      kind: 'struct',
      fields: [
        ['d', [32]]
      ]
    }
  ], [
    HashesList,
    {
      kind: 'struct',
      fields: [
        ['d', [32]]
      ]
    }
  ], [
    MaintenanceRecord,
    {
      kind: 'struct',
      fields: [
        ['account_type', 'u8'],
        ['maintained_address', [32]],
        ['authority', [32]],
        ['delegate', [DelegateList]],
        ['hashes', [HashesList]]
      ]
    }
  ], [
    BufferData,
    {
      kind: 'struct',
      fields: [
        ['timestamp', 'u64'],
        ['type', 'u32'],
        ['comment', 'string']
      ]
    }
  ]
]);
