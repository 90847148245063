import { LAMPORTS_PER_SOL } from '@solana/web3.js';

export function stringShort(str: string, max = 15, concat = '..'): string {
  const len = str.length;
  if (len > max) {
    const half = Math.floor((max - concat.length) / 2);
    return `${str.slice(0, half)}${concat}${str.slice(str.length - half)}`;
  }
  return str;
}

export function sol(lamport: number, dec = 6): string {
  return `${(lamport / LAMPORTS_PER_SOL).toFixed(dec)} SOL`;
}

export const parseLink = (pattern: string, data: { [key: string]: string | number | unknown }, root = '/'): string => {
  const path = pattern.split('/');
  return `${root}${path.map(item => data[item.slice(1)] ? data[item.slice(1)] : item).join('/')}`;
};
