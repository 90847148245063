import React, { FC, useContext } from 'react';
import { FaMoon, FaSun } from 'react-icons/fa';
import { Theme, ThemeContext } from './ThemeContext';

const Toggle: FC = () => {
  const { theme, setTheme } = useContext(ThemeContext);
  const Icon = theme === Theme.dark ? FaSun : FaMoon;
  return (
    <a onClick={() => setTheme(theme === Theme.dark ? Theme.light : Theme.dark)}
       className='transition duration-500 ease-in-out rounded-full p-2'>
      <Icon className='text-gray-400 dark:text-yellow-300 text-2xl cursor-pointer shadow-2xl' />
    </a>
  );
};

export default Toggle;
