import { useEffect, useMemo, useState } from 'react';
import { ProgramStatus } from '../maintenance/models';
import { useProgram } from '../common/components/wallet/ProgramContext';
import { NeonKey } from '../models';

export function useProgramStatus(): { status: ProgramStatus, version?: string, statusBg: any, updateFavicon: any } {
  const [status, setStatus] = useState<ProgramStatus>(ProgramStatus.unknown);
  const [version, setVersion] = useState<string>();
  const { programBuffer } = useProgram();
  const [proxyInfo] = programBuffer;

  const statusBg = useMemo(() => {
    let result = { bg: '#FFF3CA', color: '#FCCA1B' };
    switch (status) {
      case ProgramStatus.work:
        result = { bg: '#DCFCE7', color: '#69F287' };
        break;
      case ProgramStatus.stop:
        result = { bg: '#FFE4E4', color: '#E12121' };
        break;
      case ProgramStatus.unknown:
        result = { bg: '#FFF3CA', color: '#FCCA1B' };
        break;
    }
    return result;
  }, [status]);

  const updateFavicon = (status?: string): void => {
    let link = document.querySelector<HTMLLinkElement>(`link[rel~='icon']`);
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = `/favicon${status ? `_${status}` : ''}.png`;
  };

  useEffect(() => {
    if (proxyInfo) {
      setStatus(proxyInfo.elf?.params.get(NeonKey.STATUS_NAME) as ProgramStatus);
      setVersion(proxyInfo.elf?.params.get(NeonKey.PKG_VERSION));
    } else {
      setStatus(ProgramStatus.unknown);
      setVersion('');
    }
  }, [proxyInfo]);

  useEffect(() => {
    let result = 'unknown';
    switch (status) {
      case ProgramStatus.work:
        result = 'online';
        break;
      case ProgramStatus.stop:
        result = 'offline';
        break;
      case ProgramStatus.unknown:
        result = 'unknown';
        break;
    }
    updateFavicon(result);
  }, [status]);

  return { status, version, statusBg, updateFavicon };
}
