import React from 'react';
import { Button } from 'antd';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';

const WalletConnect = () => {
  const { visible, setVisible } = useWalletModal();
  const onConnect = (): void => setVisible(!visible);

  return (<div className={'flex flex-row justify-center'}>
    <div className={'flex flex-col items-center justify-between p-4 w-full max-w-2xl'}>
      <Button onClick={onConnect} className={'button blue w-full'}>
        Connect wallet
      </Button>
    </div>
  </div>);
};

export default WalletConnect;
