import React from 'react';
import LayoutRouter from './common/layout/LayoutRouter';

function App() {
  return (
    <LayoutRouter />
  );
}

export default App;
