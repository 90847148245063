import React, { FC, useEffect, useMemo, useState } from 'react';
import { MdOutlineEmergency, MdSettings } from 'react-icons/md';
import { Link, NavLink } from 'react-router-dom';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { RoutePath } from '../../../models';
import MobileMenu from './MobileMenu';
import { sol } from '../../../utils';

const Header: FC = () => {
  const [top, setTop] = useState(true);
  const [balance, setBalance] = useState('');
  const { publicKey, connected } = useWallet();
  const { connection } = useConnection();

  const balanceRequest = async () => {
    if (publicKey) {
      const balance = await connection.getBalance(publicKey);
      setBalance(sol(balance));
    }
  };
  useEffect(() => {
    const scrollHandler = () => setTop(!(window.pageYOffset > 10));
    window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
  });

  useMemo(balanceRequest, [connected, publicKey]);

  const shadow = !top && ` bg-white dark:bg-gray-900 backdrop-blur-sm shadow-lg`;

  return (
    <header
      className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out${shadow}`}>
      <div className='max-w-6xl mx-auto px-5 sm:px-6'>
        <div className='flex items-center justify-between h-16 md:h-20'>
          <div className='flex-shrink-0 mr-4'>
            <Link to={RoutePath.home} title={'Logo'}>
              <MdOutlineEmergency
                className={'text-emerald-400 dark:text-gray-50 text-2xl cursor-pointer'} />
            </Link>
          </div>
          <nav className='flex flex-grow'>
            <div className='md:flex hidden flex-grow justify-start flex-wrap items-center'>
              <NavLink className={'navbar-link'} to={RoutePath.home}>Emergency</NavLink>
              <NavLink className={'navbar-link'} to={RoutePath.groups}>MultiSig</NavLink>
            </div>
            <div className='flex flex-grow justify-end flex-wrap items-center'>
              {connected && <><strong className={'mr-2'} onClick={balanceRequest}>{balance}</strong></>}
              <WalletMultiButton className={'button'} />
              <NavLink className={'md:inline-block hidden btn-sm icon ml-4'}
                       to={RoutePath.settings}>
                <MdSettings />
              </NavLink>
            </div>
          </nav>
          <MobileMenu className={'md:hidden ml-4'} />
        </div>
      </div>
    </header>
  );
};

export default Header;
