import React, { FC } from 'react';
import { FcMenu } from 'react-icons/fc';
import { MdSettings } from 'react-icons/md';
import { Dropdown } from 'antd';
import { NavLink } from 'react-router-dom';
import { RoutePath } from '../../../models';

const menu: FC = () => {
  return (
    <div className={'bg-gray-50 dark:bg-gray-800 shadow-md p-4'}>
      <div className='flex flex-col'>
        <div className='flex flex-grow mb-2 justify-end'>
          <NavLink className={'btn-sm icon'} to={RoutePath.settings}>
            <MdSettings />
          </NavLink>
        </div>
        <NavLink className={'navbar-link'} to={RoutePath.home}>Emergency</NavLink>
        <NavLink className={'navbar-link'} to={RoutePath.groups}>MultiSig</NavLink>
        {/*<NavLink className={'navbar-link'} to={RoutePath.about}>About</NavLink>*/}
      </div>
    </div>
  );
};

const MobileMenu: FC<any> = (props) => {
  return (
    <>
      <Dropdown {...props} onClick={() => null} overlay={menu} placement='bottomRight'>
        <a className='transition duration-500 ease-in-out rounded-full p-2'>
          <FcMenu
            className='text-gray-400 dark:text-yellow-300 text-2xl cursor-pointer shadow-2xl' />
        </a>
      </Dropdown>
    </>
  );
};

export default MobileMenu;
