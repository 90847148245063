import { SettingsFormState, SettingsStorage } from '../models';
import { environment } from '../environments/config/environment';
import { useLocalStorage } from './storage';

export const SETTINGS_FORM_DEFAULT: SettingsFormState = {
  programId: environment.multisigProgram,

  governanceProgramId: environment.governanceProgram,
  neonEvmProgramId: environment.neonEvmProgram,
  maintenanceProgramId: environment.maintenanceProgram,
  multisigProgramId: environment.multisigProgram,

  solanaRpcApi: environment.url.solanaRpcApi,
  neonProxyRpcApi: environment.url.neonProxyRpcApi,
  network: 'custom'
};

export const useSettingsStorage = (): [SettingsFormState, (value: SettingsFormState) => void, () => void] => {
  return useLocalStorage<SettingsFormState>(SettingsStorage.settings, SETTINGS_FORM_DEFAULT);
};
