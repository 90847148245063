import React, { FC } from 'react';
import ThemeToggle from '../../../common/components/theme/ThemeToggle';

const Footer: FC = () => {
  return (<footer>
    <div className={'max-w-6xl mx-auto px-4'}>
      <div
        className={'flex items-center justify-between py-4 border-t border-gray-200 dark:border-gray-800'}>
        <div className={'text-sm text-gray-600 dark:text-gray-50 mr-4'}>
          Copy &copy; 2022
        </div>
        <div className={'flex md:order-1 md:ml-4'}>
          <ThemeToggle />
        </div>
      </div>
    </div>
  </footer>);
};

export default Footer;
