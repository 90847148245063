import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

export interface ThemeContext {
  theme: Theme;
  setTheme: Dispatch<SetStateAction<Theme>>;
}

export const enum ThemeStorage {
  theme = 'theme'
}

export const enum Theme {
  dark = 'dark',
  light = 'light'
}

export const getInitialTheme = (): Theme => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const storedPrefs = localStorage.getItem(ThemeStorage.theme) as Theme;
    if (storedPrefs) {
      return storedPrefs;
    }

    const userMedia = window.matchMedia('(prefers-color-scheme: light)');
    if (userMedia.matches) {
      return Theme.light;
    }
  }

  return Theme.light;
};

export const ThemeContext = createContext<ThemeContext>({} as ThemeContext);

export const ThemeProvider: FC<{ initialTheme?: Theme, children: ReactNode }> =
  ({ initialTheme, children }) => {
    const [theme, setTheme] = useState(Theme.light);

    const rawSetTheme = (rawTheme: Theme) => {
      const root = window.document.documentElement;
      const isDark = rawTheme === Theme.dark;

      root.classList.remove(isDark ? Theme.light : Theme.dark);
      root.classList.add(rawTheme);

      localStorage.setItem(ThemeStorage.theme, rawTheme);
    };

    if (initialTheme) {
      rawSetTheme(initialTheme);
    }

    useEffect(() => {
      rawSetTheme(theme);
    }, [theme]);

    return (
      <ThemeContext.Provider value={{ theme, setTheme }}>
        {children}
      </ThemeContext.Provider>
    );
  };
