export function arrayEquals(a: Uint8Array, b: Uint8Array) {
  return a.length === b.length && a.every((val, index) => val === b[index]);
}

export function findBufferLength(buffer: Uint8Array | Buffer | number[]): number | -1 {
  const bufferLength = buffer.length;
  let i = bufferLength - 1;
  while (i > 0) {
    if (buffer[i] !== 0x0) {
      return i + 1;
    }
    i--;
  }
  return -1;
}

export function equal(buf1: Uint8Array, buf2: Uint8Array): boolean {
  if (buf1.byteLength !== buf2.byteLength) return false;
  for (let i = 0; i < buf1.byteLength; i++) {
    if (buf1[i] != buf1[i]) return false;
  }
  return true;
}
