import React, { FC, lazy } from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { RoutePath } from '../../models';
import { emergencyRouterPath } from '../../maintenance/layout/Router';
import LayoutContent from '../../multisig/layout/Layout';
import { ThemeProvider } from '../components/theme/ThemeContext';
import { lazyRetry } from './lazy-loading';

const AboutPage = lazy(() => lazyRetry(() => import('../pages/AboutPage'), 'AboutPage'));
const SettingsPage = lazy(() => lazyRetry(() => import('../pages/SettingsPage'), 'SettingsPage'));
const NotFoundPage = lazy(() => lazyRetry(() => import('../pages/404'), '404'));

export const RouterPages = () => {
  return useRoutes([
    emergencyRouterPath,
    // multiSigRouterPath,
    {
      path: RoutePath.home, element: <LayoutContent />, children: [
        { path: RoutePath.about, element: <AboutPage /> },
        { path: RoutePath.settings, element: <SettingsPage /> },
        { path: RoutePath.all, element: <NotFoundPage /> }
      ]
    }
  ]);
};

export const LayoutRouter: FC = () => {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <RouterPages />
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default LayoutRouter;
