import React, { createContext, FC, ReactNode, useContext, useRef, useState } from 'react';
import { useOnClickOutside } from '../../../hooks';


export interface ConfirmDialogData {
  title: string;
  description?: string;
  confirmText: string;
  cancelText?: string;

  onConfirm?(): Promise<void>;

  onCancel?(): Promise<void>;
}

export interface ConfirmDialogContext {
  show: boolean;

  showDialog(data: ConfirmDialogData): Promise<void>;

  closeDialog(): Promise<void>;
}

export const ConfirmDialogContext = createContext<ConfirmDialogContext>({} as ConfirmDialogContext);

export function useConfirmDialog(): ConfirmDialogContext {
  return useContext(ConfirmDialogContext);
}

export const ConfirmDialogProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [show, setModal] = useState<boolean>(false);
  const [data, setData] = useState<ConfirmDialogData>();

  const showDialog = async (data: ConfirmDialogData): Promise<void> => {
    await setData(data);
    setModal(true);
  };

  const closeDialog = async (): Promise<void> => {
    setModal(false);
  };

  return (
    <ConfirmDialogContext.Provider value={{ show, showDialog, closeDialog }}>
      {children}
      {show && data && <ConfirmDialog data={data} />}
    </ConfirmDialogContext.Provider>
  );
};

const ConfirmDialog: FC<{ data: ConfirmDialogData }> = ({ data }) => {
  const bodyRef = useRef<HTMLDivElement>(null);
  const { title, description, confirmText = 'Close', cancelText, onConfirm, onCancel } = data;
  const { closeDialog } = useConfirmDialog();

  const handleConfirm = async (): Promise<void> => {
    if (typeof onConfirm === 'function') {
      await onConfirm();
    }
    await closeDialog();
  };

  const handleCancel = async (): Promise<void> => {
    if (typeof onCancel === 'function') {
      await onCancel();
    }
    await closeDialog();
  };

  useOnClickOutside(bodyRef, () => {
    handleCancel();
  });

  return (<>
    <div
      className={'flex flex-row fixed left-0 top-0 right-0 bottom-0 backdrop-blur-[5.5px] bg-[#00000026]'}>
      <div className={'flex flex-row items-center justify-center w-full z-20 '}>
        <div ref={bodyRef}
             className={'flex flex-col w-full py-4 px-6 w-full bg-white max-w-sm rounded-[10px]'}>
          <h3 className={'text-base font-bold text-center mb-5'}>{title}</h3>
          {description && <div className={'text-2xl text-center mb-6'}>{description}</div>}
          <div className={'flex flex-row justify-center space-x-4'}>
            {cancelText &&
              <a className={'btn border-0'} onClick={handleCancel}>{cancelText}</a>}
            {confirmText &&
              <a onClick={handleConfirm}
                 className={'btn bg-[#FFC7C7] rounded-full border-0 px-6'}>{confirmText}</a>}
          </div>
        </div>
      </div>
    </div>
  </>);
};

export default ConfirmDialog;
