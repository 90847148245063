import {
  AccountInfo,
  AccountMeta,
  Commitment,
  ComputeBudgetProgram,
  Connection,
  DataSlice,
  PublicKey,
  RpcResponseAndContext,
  SignatureResult,
  SYSVAR_CLOCK_PUBKEY,
  SYSVAR_RENT_PUBKEY,
  Transaction,
  TransactionInstruction,
  TransactionSignature
} from '@solana/web3.js';
import { Buffer } from 'buffer';
import { deserialize, serialize } from 'borsh';
import BN from 'bn.js';

import { GroupProposal, GroupsState, ProgramBufferType, ProposalsCombine } from '../../models';
import { equal, findBufferLength, toBytesInt32 } from '../../utils';
import { MultiSigProgram } from '../multisig/program';
import { UPGRADEABLE_BPF_LOADER_PROGRAM_ID } from '../multisig/loader';
import {
  CloseProposalInstruction,
  InstructionData,
  multiSigSchema,
  ProposalConfig,
  ProposedAccountMeta,
  ProposedInstruction,
  ProposeInstruction
} from '../multisig/models/schema';
import { maintenanceGroupsList } from '../multisig/groups';
import { groupProposals } from '../multisig/proposals';
import { BufferData, MaintenanceRecord, ProgramBufferData, schema } from './schema';

export class MaintenanceProgram {
  maintenanceKey: PublicKey;
  neonEvmKey: PublicKey;
  multiSigKey: PublicKey;
  memoKey: PublicKey;
  payerKey: PublicKey;
  multiSigProgram: MultiSigProgram;
  connection: Connection;
  maintenanceRecordKey: PublicKey;
  maintenanceProgramKey: PublicKey;
  maintenanceRecordData: MaintenanceRecord;
  buffers: PublicKey[] = [];
  bufferAccounts: ProgramBufferData[] = [];

  constructor(config: { maintenance: string, neonEvm: string, multisig: string, memo: string, payer: string, connection: Connection }) {
    const { maintenance, neonEvm, multisig, connection, memo, payer } = config;
    this.maintenanceKey = new PublicKey(maintenance);
    this.neonEvmKey = new PublicKey(neonEvm);
    this.multiSigKey = new PublicKey(multisig);
    this.memoKey = new PublicKey(memo);
    this.payerKey = new PublicKey(payer);
    this.multiSigProgram = new MultiSigProgram(this.multiSigKey);
    this.connection = connection;
  }

  getMaintenanceRecordAddress(neonEvmKey: PublicKey, maintenanceKey: PublicKey): Promise<[PublicKey, number]> {
    return PublicKey.findProgramAddress([new Buffer('maintenance', 'utf8'), neonEvmKey.toBuffer()], maintenanceKey);
  }

  getMaintenanceProgramAddress(neonEvmKey: PublicKey): Promise<[PublicKey, number]> {
    return PublicKey.findProgramAddress([neonEvmKey.toBuffer()], UPGRADEABLE_BPF_LOADER_PROGRAM_ID);
  }

  async initMaintenanceData(): Promise<MaintenanceRecord | null> {
    const [maintenanceProgramKey] = await this.getMaintenanceProgramAddress(this.neonEvmKey);
    const [maintenanceRecordAddress] = await this.getMaintenanceRecordAddress(this.neonEvmKey, this.maintenanceKey);
    this.maintenanceProgramKey = maintenanceProgramKey;
    this.maintenanceRecordKey = maintenanceRecordAddress;

    try {
      const maintenanceRecordData = await this.getMaintenanceRecordData();
      if (maintenanceRecordData) {
        this.maintenanceRecordData = maintenanceRecordData;
        await this.getBuffers(this.maintenanceRecordData.hashes);
        return this.maintenanceRecordData;
      }
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  isValidProgramBuffer = (buffer: ProgramBufferData | null, programAccounts: ProgramBufferData[]): boolean => {
    if (buffer && programAccounts.length) {
      const pubkey = buffer.pubkey;
      const index = programAccounts.findIndex(b => b.pubkey.equals(pubkey));
      if (index > -1 && this.maintenanceRecordData) {
        const { hashes } = this.maintenanceRecordData;
        const programBuffer = programAccounts[index];
        return hashes?.length ? hashes.some(hash => equal(hash, programBuffer.hash)) : false;
      }
    }
    return false;
  };

  createUpgradeInstruction(
    maintenanceProgram: PublicKey,
    maintenanceRecord: PublicKey,
    programBuffer: PublicKey, // new buffer for program
    authority: PublicKey, // Protected group key
    spillAddress: PublicKey // Wallet key
  ): TransactionInstruction {
    const keys: AccountMeta[] = [
      { pubkey: UPGRADEABLE_BPF_LOADER_PROGRAM_ID, isSigner: false, isWritable: false }, /// 0. `[]` Bpf Loader Upgradeable Program Id
      { pubkey: SYSVAR_RENT_PUBKEY, isSigner: false, isWritable: false }, /// 1. `[]` Sysvar Rent Program Id
      { pubkey: SYSVAR_CLOCK_PUBKEY, isSigner: false, isWritable: false }, /// 2. `[]` Sysvar Clock Program Id
      { pubkey: this.neonEvmKey, isSigner: false, isWritable: true }, /// 3. `[writable]` Maintained program account
      { pubkey: maintenanceProgram, isSigner: false, isWritable: true }, /// 4. `[writable]` Maintained program data account
      { pubkey: programBuffer, isSigner: false, isWritable: true }, /// 5. `[writable]` Upgrade buffer account
      { pubkey: maintenanceRecord, isSigner: false, isWritable: false }, /// 6. `[]` MaintenanceRecord
      { pubkey: authority, isSigner: true, isWritable: false }, /// 7. `[signer]` Authority
      { pubkey: spillAddress, isSigner: false, isWritable: true } /// 8. `[writable]` Spill account
    ];

    console.table(keys.map(({ pubkey, ...data }) => ({ pubkey: pubkey.toBase58(), ...data })));

    return new TransactionInstruction({
      programId: this.maintenanceKey,
      keys,
      data: Buffer.from([0x03])
    });
  }

  createBufferAuthorityInstruction(programBuffer: PublicKey, currentAuthority: PublicKey, newAuthority: PublicKey, bufferData?: BufferData): TransactionInstruction {
    const keys: AccountMeta[] = [
      { pubkey: programBuffer, isWritable: true, isSigner: false }, // buffer
      { pubkey: currentAuthority, isWritable: false, isSigner: true }, // protected group
      { pubkey: newAuthority, isWritable: false, isSigner: false } // maintenance record
    ];
    const a = Buffer.from(toBytesInt32(4));
    const b = serialize(schema, bufferData);
    return new TransactionInstruction({
      programId: UPGRADEABLE_BPF_LOADER_PROGRAM_ID,
      keys,
      data: Buffer.concat([a, b])
    });
  }

  async propose(context: {
    programBuffer: PublicKey,
    groupAccount: PublicKey,
    walletKey: PublicKey,
    spillAddress: PublicKey,
    comment: string,
    bufferData: BufferData,
    type: ProgramBufferType
  }): Promise<{ proposalKey: PublicKey, transaction: Transaction, protectedGroup: PublicKey }> {
    const {
      programBuffer,
      groupAccount,
      walletKey,
      spillAddress,
      bufferData,
      type
    } = context;
    const transaction = new Transaction({ feePayer: walletKey });
    const protectedGroup = await this.multiSigProgram.protectedAccountKey(groupAccount);
    const bufferAccountData = await this.bufferAccountData(programBuffer);
    const [maintenanceProgram] = await this.getMaintenanceProgramAddress(this.neonEvmKey);
    const [maintenanceRecord] = await this.getMaintenanceRecordAddress(this.neonEvmKey, this.maintenanceKey);

    if (!(bufferAccountData?.owner?.equals(UPGRADEABLE_BPF_LOADER_PROGRAM_ID)) || !(bufferAccountData?.authority?.equals(protectedGroup))) {
      throw new Error(`Error: Buffer isn't allowed, prepare buffer before create proposal`);
    }

    const proposedInstructions: ProposedInstruction[] = [];
    if (!maintenanceRecord.equals(bufferAccountData!.authority!)) {
      const authorityInstruction = this.createBufferAuthorityInstruction(programBuffer, bufferAccountData!.authority!, maintenanceRecord, bufferData);
      const meta = authorityInstruction.keys.map(k => new ProposedAccountMeta(k.pubkey, k.isSigner, k.isWritable));
      const proposedAuthorityInstruction = new ProposedInstruction(UPGRADEABLE_BPF_LOADER_PROGRAM_ID, meta, authorityInstruction.data);
      proposedInstructions.push(proposedAuthorityInstruction);
    }

    const {
      programId,
      keys,
      data
    } = await this.createUpgradeInstruction(maintenanceProgram, maintenanceRecord, programBuffer, protectedGroup, spillAddress);
    const meta = keys.map(k => new ProposedAccountMeta(k.pubkey, k.isSigner, k.isWritable));
    const proposedInstruction = new ProposedInstruction(programId, meta, data);
    proposedInstructions.push(proposedInstruction);

    const salt = `${Date.now().toString()}${type === ProgramBufferType.stop ? '1' : ''}`;
    const proposalConfig = new ProposalConfig({
      group: Uint8Array.from(protectedGroup.toBuffer()),
      instructions: proposedInstructions,
      author: Uint8Array.from(walletKey.toBuffer()),
      salt
    });
    const proposalKey = await this.multiSigProgram.proposalAccountKey(proposalConfig);
    const proposalSpace = this.multiSigProgram.proposalAccountSpace(proposalConfig);
    // console.log(proposalSpace);
    const rent = await this.connection.getMinimumBalanceForRentExemption(proposalSpace);
    const proposeInstruction = new ProposeInstruction(proposedInstructions, rent, parseInt(salt));

    transaction.add(await this.multiSigProgram.propose(proposeInstruction, groupAccount, walletKey));
    return { proposalKey, transaction, protectedGroup };
  }

  async approve(context: { proposalKey: PublicKey, walletKey: PublicKey, budget?: boolean }): Promise<Transaction> {
    const { proposalKey, walletKey, budget } = context;
    const proposalAccount = await this.findAccount(proposalKey);
    const proposalData = this.multiSigProgram.readProposalAccountData(proposalAccount);
    const approve = await this.multiSigProgram.approve(proposalKey, proposalData.config, walletKey);
    const transaction = new Transaction();
    if (budget) {
      transaction.add(ComputeBudgetProgram.requestUnits({ units: 1200000, additionalFee: 0 }));
    }
    return transaction.add(approve);
  }

  async closeTransaction(context: { proposalKey: PublicKey, groupKey: PublicKey, walletKey: PublicKey }): Promise<Transaction> {
    const { proposalKey, walletKey, groupKey } = context;
    await this.findAccount(proposalKey);
    const instructionData = new InstructionData(new CloseProposalInstruction());
    const buffer = serialize(multiSigSchema, instructionData);
    const keys = [
      { pubkey: walletKey, isSigner: true, isWritable: true },
      { pubkey: proposalKey, isSigner: false, isWritable: true },
      { pubkey: groupKey, isSigner: false, isWritable: true }
    ];
    const data = Buffer.from(buffer);
    const instruction = new TransactionInstruction({ programId: this.multiSigKey, keys, data });
    return new Transaction().add(instruction);
  }

  async findAccount(proposalKey: PublicKey): Promise<AccountInfo<Buffer>> {
    const account = await this.connection.getAccountInfo(proposalKey);
    if (account === null) {
      throw `Error: Can't find the Proposal Account`;
    }
    return account;
  }

  async groups(): Promise<GroupsState[]> {
    return maintenanceGroupsList(this.connection, this.multiSigProgram, this.maintenanceRecordData);
  }

  async proposals(): Promise<ProposalsCombine> {
    const groups = await this.groups();
    const data = groups.map(group => groupProposals(this.connection, this.multiSigProgram, group));
    const items = await Promise.all(data);
    return { groups, proposals: items.flat() };
  }

  async getMaintenanceRecordData(): Promise<MaintenanceRecord | null> {
    const account = await this.connection.getAccountInfo(this.maintenanceRecordKey, this.connection.commitment);
    if (account) {
      return deserialize(schema, MaintenanceRecord, account.data.slice(0, findBufferLength(account.data)));
    }
    return null;
  }

  async getBuffer(hash: Uint8Array): Promise<PublicKey> {
    const slice = hash.map(d => d & 0x7f);
    const seed = Buffer.from(slice).toString('ascii');
    return PublicKey.createWithSeed(this.payerKey, seed, UPGRADEABLE_BPF_LOADER_PROGRAM_ID);
  }

  toString(hash: Uint8Array): string {
    const result = [];
    for (const key of hash) {
      result.push(key.toString(16));
    }
    return result.join('');
  }

  async getBuffers(hashes: Uint8Array[]): Promise<PublicKey[]> {
    const result: PublicKey[] = [];
    try {
      for (const hash of hashes) {
        const pubkey = await this.getBuffer(hash);
        if (pubkey) {
          result.push(pubkey);
        }
      }
    } catch (e) {
      console.log(e);
    }
    this.buffers = result;
    return result;
  }

  async bufferAccountData(bufferPubkey: PublicKey): Promise<ProgramBufferData | null> {
    const account = await this.connection.getAccountInfo(bufferPubkey);
    if (account) {
      const buffer = new ProgramBufferData(bufferPubkey, account.owner, account.data);
      await buffer.getNeonProgramData();
      await buffer.getProgramHash();
      return buffer;
    }
    return null;
  }

  async getBufferAccounts(buffers: PublicKey[]): Promise<ProgramBufferData[]> {
    const result: ProgramBufferData[] = [];
    for (const buffer of buffers) {
      const item = await this.bufferAccountData(buffer);
      if (item instanceof ProgramBufferData) {
        result.push(item);
      }
    }
    this.bufferAccounts = result;
    return result;
  }

  findBuffer(bufferKey: PublicKey): ProgramBufferData | null {
    const index = this.bufferAccounts.findIndex(i => i.pubkey.equals(bufferKey));
    if (index > -1) {
      return this.bufferAccounts[index];
    }
    return null;
  }

  async bufferAccountSlot(bufferPubkey: PublicKey, dataSlice: DataSlice): Promise<number> {
    const account = await this.connection.getAccountInfo(bufferPubkey, { dataSlice });
    if (account) {
      return new BN(account.data, 'le').toNumber();
    }
    return 0;
  }

  async proposal(proposalKey: PublicKey): Promise<GroupProposal | null> {
    const result: Partial<GroupProposal> = {};
    const multiSig = this.multiSigProgram;
    const account = await this.connection.getAccountInfo(proposalKey);
    if (account) {
      const proposal = await multiSig.readProposalAccountData(account);
      if (proposal) {
        result['proposal'] = proposal;
        result['publicKey'] = proposalKey;
        const groupKey = new PublicKey(proposal.config.group);
        const groupAccount = await this.connection.getAccountInfo(groupKey);
        if (groupAccount) {
          result['group'] = multiSig.readGroupAccountData(groupAccount);
        }
      }
    } else {
      throw 'Proposal not found';
    }
    return result as GroupProposal;
  }

  async confirmTransaction(signature: TransactionSignature, commitment: Commitment): Promise<RpcResponseAndContext<SignatureResult> | null> {
    try {
      const { blockhash, lastValidBlockHeight } = await this.connection.getLatestBlockhash();
      return this.connection.confirmTransaction({
        blockhash,
        lastValidBlockHeight,
        signature
      }, commitment);
    } catch (e: unknown) {
      console.log(e instanceof Error ? e?.message : '');
      return Promise.resolve(null);
    }
  }
}
