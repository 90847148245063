export function toBytesInt32(number: number, littleEndian = true): ArrayBuffer {
  const arrayBuffer = new ArrayBuffer(4); // an Int32 takes 4 bytes
  const dataView = new DataView(arrayBuffer);
  dataView.setUint32(0, number, littleEndian); // byteOffset = 0; litteEndian = false
  return arrayBuffer;
}

export function toNumber(data: Uint8Array): number {
  const length = data.length;
  const buffer = Buffer.from(data);
  return buffer.readUIntBE(0, length);
}
