import React from 'react';

const Loading = () => {
  return (
    <div className={'absolute top-0 left-0 right-0 bottom-0'}>
      <div className={'loading flex flex-grow w-full h-full justify-center items-center'}>
        <div className='lds-spinner'>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
